import type { DynamicHelpConfig } from '../../default/cms/dynamicHelp.types';

/**
   * const emailMappingLascanaNL = {
      '0': 'klantenservice@lascana.nl',
      '1': 'bo_aftersales@ottobv.nl',
      '2': 'debiteuren@ottobv.nl',
      '3': 'bo_orderacceptatie@ottobv.nl',
      '4': 'orderacceptatie@ottobv.nl',
      '5': 'sander.trum@ottobv.nl',
      '6': 'solliciteer@ottobv.nl',
    };
  */

export const dynamicHelp: DynamicHelpConfig = {
  labelMessageKey: 'forms.contact.dynamicSubject.howCanWeHelp',
  items: [
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.order',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderPlacement',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.orderInsideNL',
              code: '%%BESBP111%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.orderInsideNL',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.orderOutsideNL',
              code: '%%AFTLE112%%',
              targetEmailID: '1',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.orderOutsideNL',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderChange',
          code: '%%BESBW121%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderChange',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderCancel',
          code: '%%BESBW131%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderCancel',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderStatus',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderStatus',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.productInfo',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.productSamples',
              code: '%%BESAR151%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.productSamples',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.productQuestion',
              code: '%%BESAR152%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.productQuestion',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },

    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.shipping',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderStatus',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderStatus2',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryOrderNotReceived',
          code: '%%BEZSB221%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryOrderNotReceived',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryWrongArticle',
          code: '%%BEZLO231%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryWrongArticle',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryDamagedArticle',
          code: '%%BEZPA241%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryDamagedArticle',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryAddress',
          code: '%%BEZAL251%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryAddress',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryAppointment',
          subHelpTopics: [
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.deliveryAppointmentMakeAppointment',
              code: '%%BEZAL261%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryAppointmentMakeAppointment',
            },
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.deliveryAppointmentCancelAppointment',
              code: '%%BEZAL262%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryAppointmentCancelAppointment',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryAppointmentService',
          subHelpTopics: [
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.deliveryAppointmentStandardService',
              code: '%%AFTLE271%%',
              targetEmailID: '1',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryAppointmentStandardService',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryAppointmentPaidService',
              subHelpTopics: [
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.deliveryAppointmentPaidServiceHose',
                  code: '%%BEZVB281%%',
                  targetEmailID: '0',
                  messageKeyAnswer:
                    'forms.contact.dynamicAnswer.deliveryAppointmentPaidServiceHose',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.deliveryAppointmentPaidServiceCollectOldFurniture',
                  code: '%%BEZVB282%%',
                  targetEmailID: '0',
                  messageKeyAnswer:
                    'forms.contact.dynamicAnswer.deliveryAppointmentPaidServiceCollectOldFurniture',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.deliveryAppointmentPaidServiceCollectOldMatress',
                  code: '%%BEZVB283%%',
                  targetEmailID: '0',
                  messageKeyAnswer:
                    'forms.contact.dynamicAnswer.deliveryAppointmentPaidServiceCollectOldMatress',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.deliveryAppointmentPaidServiceInstallKitchen',
                  code: '%%BEZVB284%%',
                  targetEmailID: '0',
                  messageKeyAnswer:
                    'forms.contact.dynamicAnswer.deliveryAppointmentPaidServiceInstallKitchen',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.deliveryAppointmentPaidServiceInstallPullswitch',
                  code: '%%BEZVB285%%',
                  targetEmailID: '0',
                  messageKeyAnswer:
                    'forms.contact.dynamicAnswer.deliveryAppointmentPaidServiceInstallPullswitch',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.deliveryAppointmentPaidServiceChangeDirection',
                  code: '%%BEZVB286%%',
                  targetEmailID: '0',
                  messageKeyAnswer:
                    'forms.contact.dynamicAnswer.deliveryAppointmentPaidServiceChangeDirection',
                },
                // This item is planned in the future and may be added later
                // {
                //   messageKeySelectOption:
                //     'forms.contact.dynamicSubject.deliveryAppointmentPaidServiceGas',
                //   code: '', // TODO: add code
                //   targetEmailID: '0',
                //   messageKeyAnswer:
                //     'forms.contact.dynamicAnswer.deliveryAppointmentPaidServiceGas',
                // },
              ],
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },

    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.returnsAndExchanges',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.exchanges',
          code: '%%RERVR311%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.exchanges',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.returnsInstructions',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.returnsSmallProducts',
              code: '%%RERVR321%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsSmallProducts',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.returnsBigProducts',
              code: '%%RERVR322%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsBigProducts',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.returnsStatus',
          subHelpTopics: [
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.returnsStatusSentLessThan2WeeksAgo',
              code: '%%RERSR331%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsStatusSentLessThan2WeeksAgo',
            },
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.returnsStatusSentMoreThan2WeeksAgo',
              code: '%%AFTRE332%%',
              targetEmailID: '1',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsStatusSentMoreThan2WeeksAgo',
            },
          ],
        },

        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.returnsAssignment',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.returnsAssignmentMake',
              code: '%%RERRM341%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsAssignmentMake',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.returnsAssignmentChange',
              code: '%%RERRM342%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsAssignmentChange',
            },

            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.returnsAssignmentCancel',
              code: '%%RERRM343%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsAssignmentCancel',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.paymentAndMoney',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.myPayments',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOverview',
              code: '%%BRVVR411%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOverview',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.customerAccount',
              code: '%%BRVVS412%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.customerAccount',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.invoiceOrBalance',
              code: '%%BRVVS413%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.invoiceOrBalance',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.refunds',
              code: '%%BRVTE414%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.refunds',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentReminder',
              code: '%%BRVOF415%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentReminder',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.directDebit',
              subHelpTopics: [
                {
                  messageKeySelectOption: 'forms.contact.dynamicSubject.directDebitRequest',
                  code: '%%BRVVR416%%',
                  targetEmailID: '1',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.directDebitRequest',
                },
                {
                  messageKeySelectOption: 'forms.contact.dynamicSubject.directDebitChange',
                  code: '%%DEBBE417%%',
                  targetEmailID: '2',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.directDebitChange',
                },
              ],
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptions',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.payingByInstallment',
              subHelpTopics: [
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.payingByInstallmentRequestMoney',
                  code: '%%ORDAE421%%',
                  targetEmailID: '3',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.creditRequestMoney',
                },
                {
                  messageKeySelectOption: 'forms.contact.dynamicSubject.payingByInstallmentAskBRK',
                  code: '%%ORDBK422%%',
                  targetEmailID: '4',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.askBRK',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.payingByInstallmentRequestPaymentArrangement',
                  code: '%%DEBBE423%%',
                  targetEmailID: '2',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentArrangement',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.payingByInstallmentQuestionAboutCreditArrangement',
                  code: '%%ORDKR424%%',
                  targetEmailID: '3',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.questionAboutCreditArrangement',
                },
              ],
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptionsIDEAL',
              code: '%%BRVBE431%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOptionsIDEAL',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptionsAcceptgiro',
              code: '%%BRVBE432%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOptionsAcceptgiro',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptionsOnlineBanking',
              code: '%%BRVBE433%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOptionsOnlineBanking',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.credit',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditPrepay',
              code: '%%BESSB441%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.creditPrepay',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditBorrowExtraMoney',
              code: '%%ORDAE442%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.creditRequestMoney',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditBKR',
              code: '%%ORDBK443%%',
              targetEmailID: '4',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.askBRK',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditPaymentScheme',
              code: '%%DEBBE444%%',
              targetEmailID: '2',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentArrangement',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditArrangement',
              code: '%%ORDKR445%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.questionAboutCreditArrangement',
            },
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.creditRequestOrChangeDirectDebit',
              code: '%%BRVVR446%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.creditRequestOrChangeDirectDebit',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.promotionsAndDiscounts',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.promotionCodeNotWorking',
          code: '%%AVKPR611%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.promotionCodeNotWorking',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.warrantyAndRepair',
      subHelpTopics: [
        {
          messageKeySelectOption:
            'forms.contact.dynamicSubject.warrantyAndRepairArticleDefectiveAfterUse',
          code: '%%PGFPA811%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.warrantyAndRepairWarrantyConditions',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.warrantyAndRepairArticleDefective',
          code: '%%PGFPA821%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.warrantyAndRepairArticleDefective',
        },
        {
          messageKeySelectOption:
            'forms.contact.dynamicSubject.warrantyAndRepairWarrantyConditions',
          code: '%%PGFGA831%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.warrantyAndRepairWarrantyConditions',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.repairStatus',
          code: '%%AFTGA841%%',
          targetEmailID: '1',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.repairStatus',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.myOTTOAndOtherQuestions',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.website',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.websiteNotWorking',
              code: '%%AVKST911%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.websiteNotWorking',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.websiteLoginNotPossible',
              code: '%%AVKIN912%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.websiteLoginNotPossible',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.websiteIncorrectInformation',
              code: '%%AVKST913%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.websiteIncorrectInformation',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.productQuestion',
          code: '%%BESAR921%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.productQuestionDetailed',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.noMoreMail',
          code: '%%AVKAF931%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.noMoreMail',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.changeData',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.changeDataAddressOrPhone',
              code: '%%AVKKL941%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataAddressOrPhone',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.changeDataPasswordOrIBAN',
              code: '%%AVKKL942%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataPasswordOrIBAN',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.changeDataEmailOrPhone',
              code: '%%AVKKL943%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataEmailOrPhone',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.birthday',
              code: '%%LORDWK944%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataBirthday',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.removeData',
              code: '%%ORDKR944%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.removeData',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.customerAccountExplanation',
          code: '%%BRVVS951%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.customerAccountExplanation',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%OV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.pressAndMedia',
          code: '%%00%%',
          targetEmailID: '5',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.pressAndMedia',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.jobsApplicationsAndVacancies',
          code: '%%00%%',
          targetEmailID: '6',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.jobsApplicationsAndVacancies',
        },
        // this will not go live until march
        // {
        //   messageKeySelectOption: 'forms.contact.dynamicSubject.reportBarrier',
        //   code: '???',
        //   targetEmailID: '0',
        //   messageKeyAnswer: 'forms.contact.dynamicAnswer.reportBarrier',
        // },
      ],
    },
  ],
};
