import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  cliplister: {
    customerId: '81856',
  },
  flixmedia: {
    distributorId: 18441,
    enabled: true,
  },
  hasKIM: false,
  price: {
    showDiscountFlag: false,
  },
  recommendations: {
    dv: {
      slots: {
        1: 'searchProductToProducts',
        3: null,
        4: 'searchReplace',
      },
    },
    atb: {
      slots: {
        1: 'searchBasketToProducts',
        2: null,
      },
    },
  },
  serviceLayer: {
    paymentMethods: 'additional_infos_payment_methods',
  },
  powerSupply: {
    pictogram: {
      included: 'https://bilder.otto.nl/i/empiriecom/FuAG_included.svg',
      notIncluded: 'https://bilder.otto.nl/i/empiriecom/FuAG_not_included.svg',
      specification: 'https://bilder.otto.nl/i/empiriecom/FuAG_specifications.svg',
    },
  },
  seoImageParams: '$format_dv_desk_132$',
};
